import * as Sentry from '@sentry/react'
import { Fragment } from 'react'
import { Money } from '@shopify/hydrogen-react'
import { useShopify } from '../../../../hooks/useShopify'
import { StandardProductCardPriceRangeProps } from './types'

export const PriceRange = Sentry.withErrorBoundary(
  ({ minPrice, maxPrice }: StandardProductCardPriceRangeProps) => {
    const { currency } = useShopify()
    return (
      <>
        <Money
          data={{
            amount: Number(minPrice).toString(),
            currencyCode: currency.active,
          }}
          as={Fragment}
        />
        {' - '}
        <Money
          data={{
            amount: Number(maxPrice).toString(),
            currencyCode: currency.active,
          }}
          as={Fragment}
        />
      </>
    )
  },
  { fallback: <Fragment /> },
)
