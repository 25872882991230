import { useEffect } from 'react'

import { useAuthContext } from '../../components/AuthProvider/AuthProvider'

import { UseAuthOptions } from './types'

export const useAuth = ({
  required,
  onUnauthenticated,
}: UseAuthOptions = {}) => {
  const {
    auth,
    status,
    logout,
    login,
    register,
    resetPassword,
    updatePassword,
    user,
  } = useAuthContext()

  useEffect(() => {
    if (required && status === 'unauthenticated') {
      if (onUnauthenticated) {
        return onUnauthenticated()
      }

      // User has ID cookie but it is invalid so logout to remove cookies.
      if (auth.hasIdCookie()) {
        logout()
        return
      }

      // User has no ID cookie so redirect to login page.
      window.location.href = auth.getPages().login
    }
  }, [required, status, onUnauthenticated])

  return {
    isAuthenticated: status === 'authenticated',
    login,
    logout,
    recoverPassword: auth.recoverPassword,
    register,
    resetPassword,
    status,
    updatePassword,
    user,
  }
}
