export const getProductData = `
fragment ProductData on Product {
  id
  title
  handle
  images(first: 6) {
    nodes {
      url
      altText
    }
  }
  priceRange {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  compareAtPriceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  options {
    name
    values
  }
  tags
  availableForSale
  badges: metafield(namespace: "lounge", key: "badges") {
    value
  }
  color: metafield(namespace: "lounge", key: "color") {
    value
  }
  canonicalColor: metafield(namespace: "lounge", key: "canonical_color") {
    value
  }
  titleMetafield: metafield(namespace: "lounge", key: "title") {
    value
  }
  priceMetafield: metafield(namespace: "lounge", key: "price") {
    value
  }
  compareAtPriceMetafield: metafield(namespace: "lounge", key: "price_compare_at") {
    value
  }
  stopSellingQuantity: metafield(namespace: "lounge", key: "stop_selling_quantity") {
    value
  }
}

fragment ProductDataWithLinkedBottoms on Product {
  ...ProductData
  linkedBottoms: metafield(namespace: "lounge", key: "linked_bottoms") {
    references(first: 6) {
      nodes {
        ...ProductData
      }
    }
  }
}

query getSwatches($ids: [ID!]!) {
  nodes(ids:$ids) {
    ...ProductDataWithLinkedBottoms
    ... on Product {
      swatches: metafield(namespace: "lounge", key: "swatches") {
        references(first: 6) {
          nodes {
            ...ProductDataWithLinkedBottoms
          }
        }
      }
    }
  }
}
`
